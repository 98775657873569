<template>
  <div class="show-course-boards">
    <div class="course-boards">
      <div class="course-boards__header">
        <div class="boards-title">
          <h4 class="title">{{ $t("g.classroom.boards") }}</h4>
          <span class="icon">
            <feather-icon size="16" icon="AlertCircleIcon" color="#135991" />
          </span>
        </div>
        <div class="boards-all-link">
          <b-link
            class="boards-link"
            :to="{
              name: 'BoardsList',
              params: { id: course_id },
            }"
          >
            <span>{{ $t("g.classroom.see_all_boards") }}</span>
            <span>
              <feather-icon size="16" icon="ChevronRightIcon" color="#135991" />
            </span>
          </b-link>
        </div>
      </div>
      <div class="borders">
        <div class="borders__container">
          <card v-for="board in boards" :board="board" :key="board.id" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { BLink } from "bootstrap-vue";
import Card from "./layout/card.vue";

export default {
  components: {
    BLink,
    Card,
  },

  props: ["boards", "course_id"],
};
</script>

<style scoped lang="scss" src="./_index.scss"></style>
