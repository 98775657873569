<template>
  <div class="show-course-header">
    <div class="show-course-header__card">
      <b-row>
        <b-col md="2">
          <div class="show-course-header__card-scores">
            <div class="scores-content">
              <h5 class="title">{{ $t("g.classroom.scores") }}:</h5>
              <span v-if="enrollments" class="text">{{
                enrollments.score
              }}</span>
              <span v-else class="text">0%</span>
            </div>
          </div>
        </b-col>
        <b-col md="8">
          <div class="show-course-header__card-course">
            <div class="course-content">
              <h4 class="course-content__name">{{ courseData.name }}</h4>
              <div class="course-content__grade">
                <h5 class="title">{{ $t("g.classroom.grade") }}</h5>
                <span class="text">.{{ courseData.edu_years[0].name }}</span>
              </div>
              <div class="course-content__progress">
                <div class="progress">
                  <div
                    v-if="enrollments"
                    class="progress-bar"
                    role="progressbar"
                    :aria-valuenow="enrollments.progress"
                    :aria-valuemin="enrollments.progress"
                    aria-valuemax="100"
                    :style="{ width: enrollments.progress }"
                  />
                </div>
                <div class="progress-text">
                  <span class="text" v-if="enrollments"
                    >{{ enrollments.progress }} done</span
                  >
                </div>
              </div>
            </div>
          </div>
        </b-col>
      </b-row>
    </div>
  </div>
</template>

<script>
import { BRow, BCol, BLink } from "bootstrap-vue";
export default {
  components: {
    BRow,
    BCol,
    BLink,
  },

  props: {
    courseData: {
      type: Object,
      default: {},
    },
    enrollments: {
      type: Object,
      default: {},
    },
  },
};
</script>

<style scoped lang="scss" src="./_index.scss"></style>
