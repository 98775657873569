<template>
  <div>
    <loading v-if="isLoading" />
    <div v-else class="show-course">
      <header-show :course-data="courseData" :enrollments="enrollments" />
      <boards :boards="courseData.boards" :course_id="courseData.id" />
      <lessons :lessons="courseData.lessons" :course_id="courseData.id" />
    </div>
  </div>
</template>

<script>
import HeaderShow from "@/components/classrooms/boards/showCourses/header/index.vue";
import Boards from "@/components/classrooms/boards/showCourses/boards/index.vue";
import Lessons from "@/components/classrooms/boards/showCourses/lessons/index.vue";
import Loading from "@/components/loading/loading.vue";

export default {
  name: "ShowCourses",
  components: {
    HeaderShow,
    Boards,
    Lessons,
    Loading,
  },

  data() {
    return {
      isLoading: true,
      isError: false,
      courseData: {},
      enrollments: {},
    };
  },

  created() {
    this.getDataCourses();
    this.getDataEnrollments();
  },

  methods: {
    getDataCourses() {
      this.$http
        .get(`guardian/courses/${this.$route.params.id}?enrolled=true`)
        .then((res) => {
          if (res.status === 200) {
            const { data } = res.data;
            this.courseData = data;
          }
        })
        .catch((err) => {
          console.log(err);
          this.isError = true;
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    getDataEnrollments() {
      this.$http
        .get(`guardian/enrollments/${this.$route.params.id}?simple=true`)
        .then((res) => {
          if (res.status === 200) {
            const { data } = res.data;
            this.enrollments = data;
          }
        })
        .catch((err) => {
          console.log(err);
          this.isError = true;
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
  },
};
</script>

<style scoped lang="scss" src="./_showCourses.scss"></style>
